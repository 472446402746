<template>
    <div class="user-info">
        <!-- <img
            class="logo"
            src="/config/logo_shu.png"
        /> -->
        <div class="head">
            <div class="left">
                欢迎使用
            </div>
        </div>

        <div class="body">
            <div class="row">
                <i class="el-icon-office-building"></i>
                {{data.companyName || '-'}}
            </div>
            <div class="row">
                <i class="el-icon-user"></i>
                {{data.userName || '-'}}
            </div>
        </div>

        <div class="foot">
            <div class="title">
                操作
            </div>

            <div class="btn-group">
                <el-button
                    v-if="data.companyTypecode"
                    type="success"
                    class="btn-login"
                    icon="el-icon-s-home"
                    size="small"
                    @click="gotoSysHandler"
                >
                    进入系统
                </el-button>

                <el-button
                    type="danger"
                    class="btn-login"
                    icon="el-icon-switch-button"
                    size="small"
                    @click="logoutHandler"
                >
                    退出登录
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: {},
        };
    },
    methods: {
        // 登录后获取用户信息
        queryUserInfo() {
            this.$get(`${this.$store.state.api.userUrl}/login-info/user`, data => {
                this.data = data.user;
            });
        },
        // 退出登录
        logoutHandler() {
            showConfirm('点击确认退出登录', '', () => {
                var isCas = this.getIsCas();

                console.log(isCas);

                if (isCas) {
                    console.log("//////");
                    const CB_URL = encodeURIComponent(`${window.location.origin}/caslogout`), // 这里过网关就会获取不到身份
                        SUBMIT_URL = `${this.$config.casUrl}/logout?service=${CB_URL}`;
                    console.log(SUBMIT_URL);
                    window.location.assign(SUBMIT_URL);
                } else {
                    console.log("090909090");
                    this.$store.dispatch('userinfo/logoutHandler');
                }
            });
        },
        // 进入系统
        gotoSysHandler() {
            this.$store.dispatch('userinfo/jumpToSys');
        },
        // cas退出登录重定向操作
        casLogoutCheck() {
            var casLogout = this.getQuery('casLogout');

            if (casLogout) {
                this.removeToken();
                this.$store.commit('userinfo/updateData', {});

                this.$router.replace('/');

                this.$emit('logout');
            }
        },
    },
    mounted() {
        this.queryUserInfo();

        this.casLogoutCheck();
    },
};
</script>

<style lang="scss" scoped>
@import '@css/var.scss';

.user-info {
    position: relative;
    .logo {
        width: 120px;
        position: absolute;
        right: -10px;
        top: -35px;
    }

    .head {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .left {
            flex: 1;
            font-size: 20px;
            color: #575d6c;
            font-weight: bold;
        }

        .right {
            a {
                color: $primaryColor;
            }
        }
    }

    .body {
        .row{
            font-size: 16px;
            font-weight: bold;
            color: #575d6c;

            & + .row{
                margin-top: 10px;
            }

            i{
                margin-right: .6em;
            }
        }
    }

    .foot {
        text-align: center;
        margin-top: 30px;
        padding-bottom: 20px;

        .title {
            color: #575d6c;
            position: relative;
            line-height: 1em;

            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                border-top: 2px dashed #e1e1e3;
                width: 30%;
            }

            &:before {
                left: 0;
            }

            &:after {
                right: 0;
            }
        }

        .btn-group{
            margin-top: 20px;
        }
    }
}
</style>