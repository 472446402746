<template>
    <div class="content">
        <div class="bidding">
            <div class="title">招标采购</div>
            <div class="zbcg_cont zbcg_cont_top">
                <div
                    class="zbcg_block"
                    v-for="(item, index) in zbcgData"
                    :key="item.title"
                    v-if="index<=3"
                    @click="jump(item)"
                >
                    <div class="zbcg_index">{{ "0" + (index + 1) }}</div>
                    <img :src="item.img" />
                    <div class="zbcg_btitle">
                        <div class="zbcg_big_title">{{ item.title }}</div>
                        <div class="zbcg_sub">{{ item.sub }}</div>
                    </div>
                    <div class="zbcg_text">{{ item.text }}</div>
                    <img
                        :src="require('@/static/images/home/jiantou.png')"
                        class="jiantou"
                    />
                </div>
                
            </div>
            <div class="zbcg_cont zbcg_cont_bottom">
                <div
                    class="zbcg_block"
                    v-for="(item, index) in zbcgData"
                    :key="item.title"
                    v-if="index>3"
                    @click="jump(item)"
                >
                    <div class="zbcg_index">{{ "0" + (index + 1) }}</div>
                    <img :src="item.img" />
                    <div class="zbcg_btitle">
                        <div class="zbcg_big_title">{{ item.title }}</div>
                        <div class="zbcg_sub">{{ item.sub }}</div>
                    </div>
                    <div class="zbcg_text">{{ item.text }}</div>
                    <img
                        :src="require('@/static/images/home/jiantou.png')"
                        class="jiantou"
                    />
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'bidding',
    data() {
        return {
            zbcgData: [
                {
                    img: require('@/static/images/home/gonggao.png'),
                    title: '招标公告',
                    text: '工程施工、货物、服务类招标项目的招标公告、更正公告信息',
                    url: './notice?type=biddingnotice',
                    // url: 'https://ecs.jtsww.com/externalLink/auctionGoingList.jsp',
                },
                {
                    img: require('@/static/images/home/gongshi.png'),
                    title: '中标候选人公示',
                    text: '工程施工、货物、服务类招标项目的中标候选人公示信息',
                    url: './notice?type=candidateanno',
                    // url: 'https://ecs.jtsww.com/externalLink/auctionCompleteList.jsp',
                },
                {
                    img: require('@/static/images/home/jieguo.png'),
                    title: '中标结果公告',
                    text: '工程施工、货物、服务类招标项目的中标结果公告信息',
                    url: './notice?type=winnotice',
                    // url: 'https://ecs.jtsww.com/externalLink/auctionResultList.jsp',
                },{
                    img: require('@/static/images/home/jieguo.png'),
                    title: '建投基准煤种采购价',
                    text: '建投基准煤种的中标结果公示信息',
                    url: './coalPriceAnno',
                    // url: 'https://ecs.jtsww.com/externalLink/auctionResultList.jsp',
                },
                {
                    img: require('@/static/images/home/caigou.png'),
                    title: '非招标采购公告',
                    // sub: '',
                    text: '非招标采购项目的公告信息',
                    url: './notice?type=inquirynoeva',
                    // url: 'https://ecs.jtsww.com/externalLink/rfqGoingList.jsp',
                },
                {
                    img: require('@/static/images/home/chengjiao.png'),
                    title: '成交结果公示/公告',
                    // sub: '（工程施工、服务类）',
                    text: '非招标采购项目的成交结果公示/公告信息',
                    url: './notice?type=winnoticeinquiry',
                },
                {
                    img: require('@/static/images/home/gongcheng.png'),
                    title: '竞价公告',
                    // sub: '(工程施工、服务类)',
                    text: '竞价采购、竞价处置类业务相关信息展示',
                    // url: 'https://ecs.jtsww.com/externalLink/gcGoingList.jsp',
                    url: './auction',
                },
            ],
        };
    },
    created() {},
    methods: {
        jump(item) {
            if (item.url) {
                window.open(item.url, '_blank');
            }
        },
    },
    mounted() {},
    computed: {},
};
</script>
<style scoped lang="scss">
@import '@css/var.scss';
.bidding {
    width: 100%;
    padding: 64px 0px;

    .title {
        font-size: 32px;
        letter-spacing: 3px;
        color: #252b3a;
        text-align: center;
        margin-bottom: 55px;
    }

    .zbcg_cont {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-right: 8px;
        padding-left: 8px;
        .zbcg_block {
            width: 384px;
            height: 371px;
            background: #fff;
            margin-top: 20px;
            padding: 56px 40px;
            position: relative;
            overflow: hidden;
            transition: all 0.3s;
            cursor: pointer;

            &:hover {
                box-shadow: 6px 6px 12px -5px rgb(0 0 0 / 30%);
                transform: scale(1.01);
            }

            .zbcg_index {
                position: absolute;
                right: -10px;
                top: -75px;
                font-family: ArialMT;
                font-size: 190px;
                letter-spacing: -5px;
                color: $primaryColor;
                opacity: 0.03;
            }
            .zbcg_btitle {
                position: relative;
                margin-top: 35px;
                .zbcg_big_title {
                    font-weight: 600;
                    color: #252b3a;
                    font-size: 18px;
                }
                .zbcg_sub {
                    font-size: 14px;
                    color: #575d6c;
                    margin-top: 10px;
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -20px;
                    left: 0px;
                    width: 64px;
                    height: 4px;
                    background: $primaryColor;
                }
            }
            .zbcg_text {
                margin-top: 38px;
                font-size: 14px;
                line-height: 25px;
                color: #575d6c;
            }
            .jiantou {
                margin-top: 18px;
                cursor: pointer;
            }
        }
    }
    .zbcg_cont_top{
        .zbcg_block{
            width: 285px;
            height: 350px;
            text-align: center;

            .zbcg_btitle {
                &::after {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
        
    }
    .zbcg_cont_bottom{
        .zbcg_block{
            margin-top: 15px;
            height: 350px;
            text-align: center;
            .zbcg_btitle {
                &::after {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
}
</style>
